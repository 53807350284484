import React from 'react';
import { useTranslation } from 'react-i18next';

function StepIndicator({ currentStep }) {
  const { t } = useTranslation();

  const steps = [
    { id: 1, label: t('incidentType') },
    { id: 2, label: t('scammerInformation') },
    { id: 3, label: t('description') },
    { id: 4, label: t('contactSupport') },
  ];

  return (
    <div className="flex justify-center mb-8">
      {steps.map((step, index) => (
        <div key={step.id} className="flex items-center">
          {/* 步驟數字 */}
          <div
            className={`w-8 h-8 flex items-center justify-center rounded-full border-2 ${
              step.id === currentStep
                ? 'bg-blue-500 border-blue-500 text-white'
                : 'bg-white border-gray-300 text-gray-500 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-400'
            }`}
          >
            {step.id}
          </div>

          {/* 步驟標題 */}
          <div className={`ml-2 ${step.id <= currentStep ? 'text-gray-900 dark:text-white' : 'text-gray-400 dark:text-gray-500'}`}>
            {step.label}
          </div>

          {/* 每步之間的橫向線條 */}
          {index !== steps.length - 1 && (
            <div className="w-12 h-1 bg-gray-300 dark:bg-gray-600 mx-4"></div>
          )}
        </div>
      ))}
    </div>
  );
}

export default StepIndicator;
