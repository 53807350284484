// src/pages/AIRiskPrediction.js
import React from 'react';

function AIRiskPrediction() {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <h1 className="text-3xl font-bold mb-6">AI風險預測</h1>
      <p>使用 AI 來預測並分析加密貨幣交易的風險。</p>
    </div>
  );
}

export default AIRiskPrediction;
