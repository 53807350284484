// frontend/src/pages/Profile.js
import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import API_URL from '../config';
import Select from 'react-select'; // 引入 react-select
import countryList from 'react-select-country-list'; // 引入國家列表
import { City } from 'country-state-city'; // 引入國家、州/省、城市數據
import MemberSidebar from '../components/MemberSidebar'; // 引入 MemberSidebar 組件
import { toast } from "react-toastify";

// 將 taiwanCities 移到組件外部
const taiwanCities = [
  '臺北市',
  '新北市',
  '基隆市',
  '新竹市',
  '桃園市',
  '新竹縣',
  '宜蘭縣',
  '臺中市',
  '苗栗縣',
  '彰化縣',
  '南投縣',
  '雲林縣',
  '高雄市',
  '臺南市',
  '嘉義市',
  '嘉義縣',
  '屏東縣',
  '澎湖縣',
  '花蓮縣',
  '臺東縣',
  '金門縣',
  '連江縣',
].map((city) => ({ value: city, label: city }));

function Profile() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [user, setUser] = useState(null);

  // 表單字段
  const [name, setName] = useState('');
  const [nickname, setNickname] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [uid, setUid] = useState('');
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [wallets, setWallets] = useState([]); 
  const [newWalletAddress, setNewWalletAddress] = useState(''); 
  const [editingWallet, setEditingWallet] = useState(null);
  const [walletComment, setWalletComment] = useState('');


  const fetchUser = useCallback(
    async (countryOptions) => {
      try {
        const res = await axios.get(`${API_URL}/api/current_user`, {
          withCredentials: true, // 確保 cookie 被傳遞
        });
        if (res.data) {
          const userData = res.data;
          setUser(userData);
          setName(userData.name || '');
          setNickname(userData.nickname || '');
          setPhoneNumber(userData.phoneNumber || '');
          setEmail(userData.email || '');
          setUid(userData.uid || '');
          setWallets(userData.whitelistedWallets || []);

          // 根據用戶資料設定國家和城市
          if (userData.country) {
            const countryOption = countryOptions.find(
              (country) => country.label === userData.country
            );
            if (countryOption) {
              setSelectedCountry(countryOption);

              if (countryOption.label === '臺灣' || countryOption.label === 'Taiwan') {
                setCities(taiwanCities);
                const cityOption = taiwanCities.find((city) => city.label === userData.city);
                if (cityOption) {
                  setSelectedCity(cityOption);
                }
              } else {
                const cityOptions = City.getCitiesOfCountry(countryOption.value).map((city) => ({
                  value: city.name,
                  label: city.name,
                }));
                setCities(cityOptions);
                const cityOption = cityOptions.find((city) => city.label === userData.city);
                if (cityOption) {
                  setSelectedCity(cityOption);
                }
              }
            }
          }
        } else {
          navigate('/login'); // 如果未登入，重定向到登入頁面
        }
      } catch (error) {
        console.error('獲取使用者資訊失敗', error);
        navigate('/login'); // 如果發生錯誤，也重定向到登入頁面
      }
    },
    [navigate] // 移除 taiwanCities，因為它已經是穩定的
  );

  useEffect(() => {
    const countryOptions = countryList()
      .getData()
      .map((country) => ({
        ...country,
        label: t(country.label), // 使用 t 函數進行翻譯
      }));
    setCountries(countryOptions);

    // 預設為臺灣
    const defaultCountry = countryOptions.find(
      (country) => country.label === '臺灣' || country.label === 'Taiwan'
    );
    setSelectedCountry(defaultCountry);

    // 根據預設國家設定城市
    if (defaultCountry) {
      if (defaultCountry.label === '臺灣' || defaultCountry.label === 'Taiwan') {
        // 如果是臺灣，使用自定義的縣市列表
        setCities(taiwanCities);
        // 預設城市為臺北市
        const defaultCity = taiwanCities.find((city) => city.label === '臺北市');
        setSelectedCity(defaultCity);
      } else {
        // 否則，使用 country-state-city 提供的城市列表
        const cityOptions = City.getCitiesOfCountry(defaultCountry.value).map((city) => ({
          value: city.name,
          label: t(city.name), // 使用 t 函數進行翻譯
        }));
        setCities(cityOptions);
        setSelectedCity(cityOptions[0]);
      }
    }

    // 獲取用戶資料
    fetchUser(countryOptions);
  }, [t, fetchUser]);

  // 當選擇國家改變時，更新城市列表
  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    setSelectedCity(null); // 重置選擇的城市

    if (selectedOption.label === '臺灣' || selectedOption.label === 'Taiwan') {
      // 如果選擇的是臺灣，使用自定義的縣市列表
      setCities(taiwanCities);
    } else {
      // 否則，使用 country-state-city 提供的城市列表
      const cityOptions = City.getCitiesOfCountry(selectedOption.value).map((city) => ({
        value: city.name,
        label: city.name,
      }));
      setCities(cityOptions);
    }
  };

  // 當選擇城市改變時
  const handleCityChange = (selectedOption) => {
    setSelectedCity(selectedOption);
  };

  const handleSave = async () => {
    // 根據選擇的國家決定是否驗證手機號碼
    if (
      selectedCountry &&
      (selectedCountry.label === '臺灣' || selectedCountry.label === 'Taiwan')
    ) {
      // 驗證手機號碼（台灣手機號碼格式）
      if (!/^09\d{8}$/.test(phoneNumber)) {
        toast.success(t('enterValidPhoneNumber'));
        return;
      }
    }

    // 更新會員資訊
    try {
      await axios.post(
        `${API_URL}/api/update_profile`,
        {
          name,
          nickname,
          country: selectedCountry ? selectedCountry.label : '',
          city: selectedCity ? selectedCity.label : '',
          phoneNumber,
          whitelistedWallets: wallets,
        },
        { withCredentials: true }
      );
      toast.success(t('updateSuccess'));
    } catch (err) {
      console.error('更新失敗', err);
      toast.error(t('updateFailed'));
    }
  };

  // 新增錢包地址
  const handleAddWallet = async () => {
    if (newWalletAddress && !wallets.includes(newWalletAddress)) {
      try {
        const response = await axios.post(
          `${API_URL}/api/update_wallet_addresses`,
          {
            walletAddress: newWalletAddress,
            action: 'add',
          },
          { withCredentials: true }
        );
        setWallets(response.data.whitelistedWallets); // 從後端獲取最新的錢包地址列表
        setNewWalletAddress('');
        toast.success(t('walletAdded'));
      } catch (error) {
        console.error('新增錢包地址失敗', error);
        toast.error(t('walletAddFailed'));
      }
    }
  };

// 移除錢包地址
const handleRemoveWallet = async (walletAddress) => {
  try {
    const response = await axios.post(
      `${API_URL}/api/update_wallet_addresses`,
      {
        walletAddress, // 使用 _id 作為移除參數
        action: 'remove',
      },
      { withCredentials: true }
    );
    setWallets(response.data.whitelistedWallets);
    toast.success(t('walletRemoved'));
  } catch (error) {
    console.error('移除錢包地址失敗', error);
    toast.error(t('walletRemoveFailed'));
  }
};

// 點擊編輯按鈕時觸發，設定當前編輯中的錢包
const handleEditWallet = (wallet) => {
  setEditingWallet(wallet);
  setWalletComment(wallet.comment || ''); // 預設為現有的備註或空
};

const handleSaveComment = async () => {
  if (editingWallet) {
    try {
      const response = await axios.post(
        `${API_URL}/api/update_wallet_addresses`,
        {
          walletAddress: editingWallet.address,
          action: 'update',
          comment: walletComment, // 新的備註內容
        },
        { withCredentials: true }
      );
      setWallets(response.data.whitelistedWallets); // 更新本地狀態
      setEditingWallet(null); // 重置編輯狀態
      setWalletComment(''); // 清空備註
      toast.success(t('commentUpdated'));
    } catch (error) {
      console.error('備註更新失敗', error);
      toast.error(t('updateCommentFailed'));
    }
  }
};




  if (!user) return <div>{t('loading')}</div>;

  return (
    <div className="bg-gray-100 dark:bg-gray-900 min-h-screen">
      {/* 頁面橫幅 */}
      <div
        className="w-full h-64 bg-cover bg-center flex items-center justify-center text-white text-3xl font-bold"
        style={{ backgroundImage: `url('/images/myReportsBanner.png')` }}
      >
        {t('profileInfo')}
      </div>

      <div className="flex flex-col md:flex-row w-full max-w-7xl mx-auto mt-6 px-4">
        {/* 左側頭像和導航區 */}
        <MemberSidebar activePage="profile" />

        {/* 右側會員資訊編輯區 */}
        <div className="flex-1 ml-0 md:ml-6">
          <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg mb-6">
            <h3 className="text-2xl font-bold mb-4">{t('profileInfo')}</h3>
            <form>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-gray-700 dark:text-gray-300">{t('uid')}</label>
                  <input
                    type="text"
                    value={uid}
                    className="w-full p-2 border rounded bg-gray-200 dark:bg-gray-700"
                    disabled // 不允許修改 UID
                  />
                </div>
                <div>
                  <label className="block text-gray-700 dark:text-gray-300">{t('email')}</label>
                  <input
                    type="email"
                    value={email}
                    className="w-full p-2 border rounded bg-gray-200 dark:bg-gray-700"
                    disabled // 不允許修改 Email
                  />
                </div>
                <div>
                  <label className="block text-gray-700 dark:text-gray-300">{t('name')}</label>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="w-full p-2 border rounded"
                  />
                </div>
                <div>
                  <label className="block text-gray-700 dark:text-gray-300">{t('nickname')}</label>
                  <input
                    type="text"
                    value={nickname}
                    onChange={(e) => setNickname(e.target.value)}
                    className="w-full p-2 border rounded"
                  />
                </div>
                {/* 國家下拉選單 */}
                <div>
                  <label className="block text-gray-700 dark:text-gray-300">{t('country')}</label>
                  <Select
                    options={countries}
                    value={selectedCountry}
                    onChange={handleCountryChange}
                  />
                </div>
                {/* 城市下拉選單 */}
                <div>
                  <label className="block text-gray-700 dark:text-gray-300">{t('city')}</label>
                  <Select
                    options={cities}
                    value={selectedCity}
                    onChange={handleCityChange}
                    isDisabled={!selectedCountry} // 當未選擇國家時禁用
                  />
                </div>

                <div>
                  <label className="block text-gray-700 dark:text-gray-300">
                    {t('phoneNumber')}
                  </label>
                  <input
                    type="text"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    className="w-full p-2 border rounded"
                  />
                </div>
              </div>
              <button
                type="button"
                onClick={handleSave}
                className="mt-4 bg-blue-500 text-white px-4 py-2 rounded"
              >
                {t('save')}
              </button>
            </form>
          </div>

          {/* 錢包地址區塊 */}
          <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg mb-6">
            <h3 className="text-2xl font-bold mb-6 text-gray-900 dark:text-white">{t('walletAddresses')}</h3>
            
            {/* 新增錢包地址輸入區塊 */}
            <div className="flex flex-col sm:flex-row items-center mb-4 space-y-2 sm:space-y-0 sm:space-x-2">
              <input
                type="text"
                value={newWalletAddress}
                onChange={(e) => setNewWalletAddress(e.target.value)}
                placeholder={t('enterUserWalletAddress')}
                className="flex-grow w-full p-2 border rounded dark:bg-gray-700 dark:text-white text-gray-900 focus:ring focus:ring-opacity-50 focus:ring-blue-400 dark:focus:ring-blue-500"
              />
              <button
                type="button"
                onClick={handleAddWallet}
                className="w-full sm:w-auto bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded transition-colors duration-200 whitespace-nowrap"
              >
                {t('addWallet')}
              </button>
            </div>

            {/* 錢包地址列表區塊 */}
            <ul className="space-y-2">
              {wallets.length > 0 ? (
                wallets.map((wallet, index) => (
                  <li
                    key={index}
                    className="flex flex-col sm:flex-row sm:items-center justify-between p-3 border rounded bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-white space-y-2 sm:space-y-0"
                  >
                    {/* 地址與備註顯示區域 */}
                    <div className="flex flex-col sm:flex-row sm:items-center space-y-2 sm:space-y-0 sm:space-x-4">
                      <span className="font-medium truncate">{wallet.address}</span>
                      {editingWallet && editingWallet.address === wallet.address ? (
                        <input
                          type="text"
                          value={walletComment}
                          onChange={(e) => setWalletComment(e.target.value)}
                          className="w-full p-2 border rounded dark:bg-gray-600"
                        />
                      ) : (
                        <span className="text-gray-500 dark:text-gray-400">{wallet.comment || t('noComment')}</span>
                      )}
                    </div>

                    {/* 操作按鈕 */}
                    <div className="flex space-x-2 mt-2 sm:mt-0">
                      {editingWallet && editingWallet.address === wallet.address ? (
                        <button
                          type="button"
                          onClick={handleSaveComment}
                          className="bg-green-500 hover:bg-green-600 text-white px-4 py-1 rounded"
                        >
                          {t('save')}
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={() => handleEditWallet(wallet)}
                          className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-1 rounded"
                        >
                          {t('edit')}
                        </button>
                      )}
                      <button
                        type="button"
                        onClick={() => handleRemoveWallet(wallet.address)}
                        className="bg-red-500 hover:bg-red-600 text-white px-4 py-1 rounded"
                      >
                        {t('remove')}
                      </button>
                    </div>
                  </li>
                ))
              ) : (
                <li className="text-gray-500 dark:text-gray-400">{t('noWalletAddresses')}</li>
              )}
            </ul>
          </div>



        </div>
      </div>
    </div>
  );
}

export default Profile;
