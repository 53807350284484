// src/pages/PathAnalysis.js
import React from 'react';

function PathAnalysis() {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <h1 className="text-3xl font-bold mb-6">受騙路徑分析</h1>
      <p>這裡可以追蹤並分析受騙者的資金流向。</p>
    </div>
  );
}

export default PathAnalysis;
