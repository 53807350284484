// src/pages/Home.js
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next'; 
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import API_URL from '../config';

function Home({ darkMode }) {
  const { t } = useTranslation();  // 使用 useTranslation 來獲取 t 函數
  const navigate = useNavigate(); 
  const [guestReport, setGuestReport] = useState(null); // 用於存儲報案資訊
  const [isExpanded, setIsExpanded] = useState(false); // 控制彈出視窗是否展開

  useEffect(() => {

    // 獲取泡泡元素
    const bubble1 = document.getElementById('bubble1');
    let isMouseMoved = false;

    const handleMouseMove = (e) => {
      if (!isMouseMoved) isMouseMoved = true;
      const mouseX = e.clientX;
      const mouseY = e.clientY;
      bubble1.style.transform = `translate(${mouseX * 0.02}px, ${mouseY * 0.02}px)`;
    };

    const handleScroll = () => {
      if (isMouseMoved) {
        const scrollY = window.scrollY;
        bubble1.style.transform = `translateY(${scrollY * 0.1}px)`;
      }
    };

    // 監聽滑鼠移動和滾動事件
    document.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('scroll', handleScroll);

    // 從 localStorage 中取得 guestId
    const guestId = localStorage.getItem('guestId');
    if (guestId) {
      // 如果有 guestId，從後端獲取報案資訊
      axios.get(`${API_URL}/api/guest_report/${guestId}`)
        .then((res) => {
          setGuestReport(res.data.report);
        })
        .catch((err) => {
          console.error(t('errorFetchingGuestReport'), err);
        });
    };

    // 清理事件監聽器
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [t]);

  // 處理點擊浮動視窗的函數
  const handleTogglePopup = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div 
      className="relative h-screen bg-cover bg-center overflow-hidden" 
      style={{ backgroundImage: `url(${darkMode ? '/images/darkHeroBanner.png' : '/images/heroBanner.png'})` }}
    >

      <div
        className="absolute top-1/3 left-0 md:left-20 min-h-[300px] md:min-h-[500px] w-full md:w-1/3 flex flex-col items-center justify-center text-center px-4 md:px-0"
      >
        <img
          src="/images/Vector.png"
          alt="Circle Background"
          className="w-full h-full object-contain"
          style={{ 
            pointerEvents: "none", 
            minHeight: "450px", 
            minWidth: "450px", 
            maxHeight: "500px",
            maxWidth: "500px"
          }} 
        />

        {/* 調整文本框大小和樣式 */}
        <div className="absolute flex flex-col items-center text-center space-y-4">
          <h1 className="text-2xl md:text-4xl font-bold mb-4 text-black">{t('welcomeToApp')}</h1>
          <p className="text-md md:text-lg text-black break-words max-w-md md:max-w-full px-4">
            {t('appDescription')}
          </p>

          {/* 按鈕區域 */}
          <div className="flex space-x-2 md:space-x-4 relative z-10 flex-wrap justify-center">
            <button 
              onClick={() => navigate('/report')}
              className="bg-[#6a0dad] hover:bg-[#5a0b8a] text-white font-bold py-2 px-4 md:py-3 md:px-6 rounded">
              {t('generateReport')}
            </button>
            <button className="border-2 border-[#6a0dad] hover:border-[#5a0b8a] text-[#6a0dad] hover:text-[#5a0b8a] font-bold py-2 px-4 md:py-3 md:px-6 rounded">
              {t('learnMore')}
            </button>
          </div>
        </div>
      </div>

      <div className="bubble-container absolute inset-0 overflow-hidden">
        <img src="/images/bubble.png" className="absolute inset-0 w-full h-full object-cover" id="bubble1" alt="Bubbles" />
      </div>

      {guestReport && (
        <div
          className={`fixed top-1/2 right-0 transform -translate-y-1/2 bg-white shadow-lg rounded-l-lg overflow-hidden transition-all duration-300 ${
            isExpanded ? 'w-60 md:w-80' : 'w-12'
          }`}
        >
          <div onClick={handleTogglePopup} className="cursor-pointer h-full flex flex-col justify-center items-center">
            {isExpanded ? (
              <div className="p-2 md:p-4">
                <h2 className="text-lg md:text-xl font-bold mb-2">{t('guestReportInfo')}</h2>
                <p><strong>{t('guestId')}:</strong> {localStorage.getItem('guestId')}</p>
                <p><strong>{t('incidentType')}:</strong> {guestReport.incidentType}</p>
                <p><strong>{t('description')}:</strong> {guestReport.description}</p>
              </div>
            ) : (
              <div className="w-12 h-12 flex items-center justify-center">
                <svg className="w-6 h-6 text-gray-700" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                </svg>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Home;
