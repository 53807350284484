// App.js

import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

import Home from './pages/Home';
import Result from './pages/Result';
import Report from './pages/Report';
import WalletRisk from './pages/WalletRisk';
import PathAnalysis from './pages/PathAnalysis';
import BlockchainAcademy from './pages/BlockchainAcademy';
import AIRiskPrediction from './pages/AIRiskPrediction';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Profile from './pages/Profile';
import MyReports from './pages/MyReports';
import Favorites from './pages/Favorites';
import ReportDetails from './pages/ReportDetails';

import Navbar from './components/Navbar'; 
import Footer from './components/Footer';
import './i18n';

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FeedbackButton from './components/FeedbackButton';
import ScrollToTop from './components/ScrollToTop';

// import CookieConsent from './components/CookieConsent';

// 初始化 Google Analytics
ReactGA.initialize('G-RBK4ZP0177');

const TrackRouteChange = () => {
  const location = useLocation();
  React.useEffect(() => {
    // 追蹤頁面瀏覽
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);
  return null;
};

function App() {
  const [darkMode, setDarkMode] = useState(false);
  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    if (!darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  };

  return (
    <Router>
      <ScrollToTop />
      <TrackRouteChange />
      <div className={`min-h-screen ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-black'}`}>
        <Navbar toggleDarkMode={toggleDarkMode} darkMode={darkMode} />
        <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
        {/* <CookieConsent /> */}

        <div className="pt-16">
          <Routes>
            <Route path="/" element={<Home darkMode={darkMode} />} />
            <Route path="/wallet-risk" element={<WalletRisk />} />
            <Route path="/result" element={<Result />} />
            <Route path="/report" element={<Report />} />
            <Route path="/login" element={<Login />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/my-reports" element={<MyReports />} />
            <Route path="/favorites" element={<Favorites />} />
            <Route path="/path-analysis" element={<PathAnalysis />} />
            <Route path="/academy" element={<BlockchainAcademy />} />
            <Route path="/ai-prediction" element={<AIRiskPrediction />} />
            <Route path="/report/:id" element={<ReportDetails />} />
          </Routes>
        </div>
        <FeedbackButton /> 
        <Footer darkMode={darkMode} />
      </div>
    </Router>
  );
}

export default App;
