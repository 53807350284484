// src/pages/Result.js
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function Result() {
  const location = useLocation();
  const navigate = useNavigate();
  const { riskData } = location.state || {};

  if (!riskData) {
    navigate('/');
    return null;
  }

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <h1 className="text-2xl font-bold mb-4">風險評估結果</h1>
      <p className="mb-6">{riskData.risk}</p>
      <button
        onClick={() => navigate('/')}
        className="bg-blue-500 text-white p-3 rounded"
      >
        返回首頁
      </button>
    </div>
  );
}

export default Result;
