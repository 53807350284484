import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import API_URL from '../config';

function Dashboard() {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  
  useEffect(() => {
    // 檢查用戶是否已登入
    const fetchUser = async () => {
      try {
        const res = await axios.get(`${API_URL}/api/current_user`, {
            withCredentials: true // 確保 cookie 被傳遞
          });
        if (res.data) {
          setUser(res.data);
        } else {
          navigate('/login'); // 如果未登入，重定向到登入頁面
        }
      } catch (error) {
        console.error('Error fetching user', error);
        navigate('/login'); // 如果發生錯誤，也重定向到登入頁面
      }
    };

    fetchUser();
  }, [navigate]);

  return user ? (
    <div className="dashboard">
      <h1>Welcome, {user.name}!</h1>
    </div>
  ) : (
    <div>Loading...</div>
  );
}

export default Dashboard;
