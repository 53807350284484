// frontend/src/components/MemberSidebar.js
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CameraIcon } from '@heroicons/react/24/solid'; // 引入相機圖標
import axios from 'axios';
import API_URL from '../config';

function MemberSidebar({ activePage }) {
  const { t } = useTranslation();
  const [avatar, setAvatar] = useState('/path/to/default-avatar.png'); // 預設頭像
  const [error, setError] = useState('');

  useEffect(() => {
    // 取得使用者頭像 URL
    axios
      .get(`${API_URL}/api/current_user`, { withCredentials: true })
      .then((res) => {
        if (res.data.avatarUrl) {
          setAvatar(res.data.avatarUrl); // 如果有頭像 URL，設置頭像
        }
      })
      .catch((err) => {
        console.error(t('errorFetchingAvatar'), err);
      });
  }, [t]);

  // 頭像上傳處理
  const handleAvatarChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // 檢查文件大小是否超過10MB
    if (file.size > 10 * 1024 * 1024) {
      setError(t('fileTooLarge'));
      return;
    }

    // 預覽頭像
    const reader = new FileReader();
    reader.onload = (e) => {
      setAvatar(e.target.result);
    };
    reader.readAsDataURL(file);

    // 上傳圖片至伺服器
    const formData = new FormData();
    formData.append('avatar', file);
    try {
      const res = await axios.post(`${API_URL}/api/upload_avatar`, formData, {
        withCredentials: true,
      });
      setAvatar(res.data.avatarUrl);
      setError('');
    } catch (err) {
      console.error(err);
      setError(t('uploadFailed'));
    }
  };

  return (
    <div className="md:w-1/4 w-full mb-6 md:mb-0">
      <div className="flex flex-col items-center">
        {/* 頭像顯示與相機圖標 */}
        <div className="relative">
          <img src={avatar} alt="User Avatar" className="w-24 h-24 rounded-full mb-4" />
          <label
            htmlFor="avatar-upload"
            className="absolute bottom-0 right-0 bg-white p-1 rounded-full cursor-pointer"
          >
            <CameraIcon className="h-6 w-6 text-gray-700" />
            <input
              type="file"
              id="avatar-upload"
              accept="image/*"
              className="hidden"
              onChange={handleAvatarChange}
            />
          </label>
        </div>
        <h3 className="text-xl font-bold mb-2">{t('memberArea')}</h3>
        <ul className="space-y-4 text-gray-700 dark:text-gray-300">
          {/* <li>
            <a href="/dashboard" className={activePage === 'dashboard' ? 'font-semibold' : ''}>
              {t('dashboard')}
            </a>
          </li> */}
          <li>
            <a href="/profile" className={activePage === 'profile' ? 'font-semibold' : ''}>
              {t('profileInfo')}
            </a>
          </li>
          <li>
            <a href="/my-reports" className={activePage === 'myReports' ? 'font-semibold' : ''}>
              {t('myReports')}
            </a>
          </li>
          {/* <li>
            <a href="/path-analysis" className={activePage === 'pathAnalysis' ? 'font-semibold' : ''}>
              {t('pathAnalysis')}
            </a>
          </li>
          <li>
            <a href="/favorites" className={activePage === 'favorites' ? 'font-semibold' : ''}>
              {t('favorites')}
            </a>
          </li> */}
        </ul>
        {error && <p className="text-red-500 mt-4">{error}</p>}
      </div>
    </div>
  );
}

export default MemberSidebar;
