import React from 'react';
import { useTranslation } from 'react-i18next';

function Footer({ darkMode }) {
  const { t } = useTranslation(); // 引入 useTranslation 來進行翻譯

  return (
    <footer
    className={`py-8  ${
      darkMode ? 'bg-gray-800 bg-opacity-80 text-gray-300' : 'bg-white bg-opacity-80 text-gray-700'
    }`}
    style={{ backdropFilter: 'blur(10px)' }} // 背景模糊效果
  >
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-between gap-8">

          {/* 公司資訊 */}
          <div className="w-full md:w-1/4">
            <h3 className="text-white text-lg font-bold mb-4">{t('companyInfo')}</h3>
            <p className="mb-2">{t('kaohsiungOffice')}</p>
            <p className="mb-4">802{t('kaohsiungAddress')}</p>
            <p className="mb-2">{t('taipeiOffice')}</p>
            <p>104{t('taipeiAddress')}</p>
          </div>

          {/* 快速導航 */}
          <div className="w-full md:w-1/4">
            <h3 className="text-white text-lg font-bold mb-4">{t('quickLinks')}</h3>
            <ul>
              <li className="mb-2">
                <a href="/about" className="text-gray-400 hover:text-white transition duration-300">{t('aboutUs')}</a>
              </li>
              <li className="mb-2">
                <a href="/services" className="text-gray-400 hover:text-white transition duration-300">{t('services')}</a>
              </li>
              <li className="mb-2">
                <a href="/report" className="text-gray-400 hover:text-white transition duration-300">{t('reportSystem')}</a>
              </li>
              <li className="mb-2">
                <a href="/academy" className="text-gray-400 hover:text-white transition duration-300">{t('blockchainAcademy')}</a>
              </li>
              <li className="mb-2">
                <a href="/contact" className="text-gray-400 hover:text-white transition duration-300">{t('contactUs')}</a>
              </li>
            </ul>
          </div>

          {/* 隱私政策與條款 */}
          <div className="w-full md:w-1/4">
            <h3 className="text-white text-lg font-bold mb-4">{t('legalAndPolicy')}</h3>
            <ul>
              <li className="mb-2">
                <a href="/privacy-policy" className="text-gray-400 hover:text-white transition duration-300">{t('privacyPolicy')}</a>
              </li>
              <li className="mb-2">
                <a href="/terms" className="text-gray-400 hover:text-white transition duration-300">{t('termsOfService')}</a>
              </li>
            </ul>
          </div>

          {/* 社交媒體 */}
          <div className="w-full md:w-1/4">
            <h3 className="text-white text-lg font-bold mb-4">{t('socialMedia')}</h3>
            <div className="flex space-x-4">
              <a href="https://www.facebook.com" className="text-gray-400 hover:text-white transition duration-300">
                <i className="fab fa-facebook-f text-xl"></i>
              </a>
              <a href="https://www.twitter.com" className="text-gray-400 hover:text-white transition duration-300">
                <i className="fab fa-twitter text-xl"></i>
              </a>
              <a href="https://www.linkedin.com" className="text-gray-400 hover:text-white transition duration-300">
                <i className="fab fa-linkedin-in text-xl"></i>
              </a>
            </div>
          </div>

        </div>

        {/* 版權聲明 */}
        <div className="text-center pt-6 border-t border-gray-700 mt-8">
          <p className="text-gray-500">&copy; {new Date().getFullYear()} Damen All Rights Reserved</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
