import React, { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import API_URL from '../config';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function FeedbackButton() {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [rating, setRating] = useState(0); // 儲存評分
  const [hoverRating, setHoverRating] = useState(0); // 滑鼠移動時的評分
  const [feedback, setFeedback] = useState(''); // 儲存意見文字
  const purpleColor = "#C1B6DD";

  const handleSubmit = async () => {
    try {
      await axios.post(`${API_URL}/api/feedback`, { rating, feedback });
      toast.success(t('feedbackSubmitted'));
      setIsOpen(false);
    } catch (error) {
      console.error(error);
      toast.error(t('feedbackSubmitError'));
    }
  };

  return (
    <div>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="fixed right-4 bottom-4 bg-blue-500 text-white p-3 rounded-full shadow-lg"
        style={{ backgroundColor: purpleColor, opacity: 0.8, zIndex: 1000 }} 
      >
        {t('feedback')}
      </button>

      {isOpen && (
        <div className="fixed right-4 bottom-16 bg-white p-6 shadow-lg rounded-lg w-80">
          <h3 className="text-xl font-bold mb-2">{t('leaveFeedback')}</h3>

          <textarea
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            placeholder={t('feedbackPlaceholder')}
            className="w-full p-2 border rounded mb-4"
          ></textarea>

          <div className="flex mb-4">
            {[1, 2, 3, 4, 5].map((star) => (
              <span
                key={star}
                onMouseEnter={() => setHoverRating(star)}
                onMouseLeave={() => setHoverRating(0)}
                onClick={() => setRating(star)}
                className={`cursor-pointer text-2xl ${
                  (hoverRating || rating) >= star ? 'text-yellow-400' : 'text-gray-300'
                }`}
              >
                ★
              </span>
            ))}
          </div>

          <button
            onClick={handleSubmit}
            className="bg-blue-500 text-white p-2 rounded w-full"
            style={{ backgroundColor: purpleColor }} 
          >
            {t('submit')}
          </button>
        </div>
      )}
    </div>
  );
}

export default FeedbackButton;
