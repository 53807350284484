// fronted/src/pages/WalletRisk.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import API_URL from '../config';
import { FaMagnifyingGlass } from "react-icons/fa6";
import { toast } from "react-toastify";

function WalletRisk() {
  const [walletAddress, setWalletAddress] = useState('');
  const [riskData, setRiskData] = useState(null);
  const [sanctionData, setSanctionData] = useState(null);
  const [chainId, setChainId] = useState(1);
  const [comment, setComment] = useState(''); // 新增備註欄位
  const [label, setLabel] = useState('scam'); // 預設標籤
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userId, setUserId] = useState(null);
  const [complianceRiskData, setComplianceRiskData] = useState(null);

  useEffect(() => {
    async function checkAuth() {
      try {
        const response = await axios.get(`${API_URL}/api/current_user`, { withCredentials: true });
        if (response.data) {
          setIsAuthenticated(true);
          setUserId(response.data._id);
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        setIsAuthenticated(false);
      }
    }
    checkAuth();
  }, []);


  const mainEntity = riskData && riskData.data && riskData.data.main_entity ? riskData.data.main_entity : t('unknown');
  const nameTag = riskData && riskData.data && riskData.data.name_tag ? riskData.data.name_tag : t('unknown');
  const attributes = riskData && riskData.data && riskData.data.attributes ? riskData.data.attributes : [];
  const riskScore = complianceRiskData && complianceRiskData.risk_score
    ? complianceRiskData.risk_score
    : t('unknown'); // 預設為未知
  const riskIndicators = complianceRiskData && complianceRiskData.risk_indicators
    ? complianceRiskData.risk_indicators
    : t('unknown'); // 當風險指標為空時顯示“未知”


  
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!walletAddress) return;
  
    // 初始化臨時變量
    let riskDataTemp = null;
    let sanctionDataTemp = null;
    let complianceRiskDataTemp = null;
  
    console.log("chainId:", chainId);
    console.log("walletAddress:", walletAddress);
  
    try {
      // 查詢風險標籤資料
      try {
        const riskResponse = await axios.post(`${API_URL}/api/predict-risk`, 
          { walletAddress, chain_id: chainId }, 
          { withCredentials: true });
        riskDataTemp = riskResponse.data;
      } catch (error) {
        console.error('Error fetching risk labels:', error);
        toast.error("風險標籤查詢失敗或用量達上限。");
      }
  
      // 查詢制裁訊息
      try {
        const sanctionResponse = await axios.get(`${API_URL}/api/check-sanction/${walletAddress}`);
        sanctionDataTemp = sanctionResponse.data;
      } catch (error) {
        console.error('Error fetching sanction data:', error);
        toast.error("制裁查詢失敗或用量達上限。");
      }
  
      // 查詢合規風險分數
      try {
        const complianceResponse = await axios.post(`${API_URL}/api/compliance-risk`, 
          { walletAddress, chain_id: chainId },
          { withCredentials: true });
        complianceRiskDataTemp = complianceResponse.data;
        // console.log("合規風險分數資料：", complianceResponse.data);
      } catch (error) {
        console.error('Error fetching compliance risk score:', error);
        toast.error("合規風險分數查詢失敗或用量達上限。");
      }
  
      // 更新 state
      setRiskData(riskDataTemp);
      setSanctionData(sanctionDataTemp);
      setComplianceRiskData(complianceRiskDataTemp);
  
      // 構建要保存的數據物件
      let saveData = {
        walletAddress,
        chainId,
        updatedAt: new Date()
      };
      if (riskDataTemp) {
        saveData.riskLabels = riskDataTemp;
      }
      if (sanctionDataTemp) {
        saveData.sanctionStatus = sanctionDataTemp;
      }
      if (complianceRiskDataTemp) {
        saveData.complianceScore = complianceRiskDataTemp;
      }
      // 僅在有數據時才儲存到資料庫
      if (Object.keys(saveData).length > 2) { // 2 是 `walletAddress` 和 `chainId` 基本字段
        await axios.post(`${API_URL}/api/save-query-history`, saveData);
      } else {
        console.log("無需儲存，沒有可儲存的有效查詢結果");
      }
  
    } catch (error) {
      console.error('Unexpected error:', error);
      toast.error("無法查詢該地址的資訊。");
    }
  };
  
  



  const handleAddToWhitelist = async () => {
    if (!walletAddress) {
      toast.error(t('enterValidWalletAddress'));
      return;
    }

    if (!isAuthenticated) {
      window.location.href = `${API_URL}/api/auth/google`;
      return;
    }

    try {
      await axios.post(`${API_URL}/api/whitelist`, { walletAddress, userId, comment });
      toast.success(t('walletAddedToWhitelist'));
    } catch (error) {
      toast.error(t('errorAddingWalletToWhitelist'));
    }
  };

  const handleAddToBlacklist = async () => {
    if (!walletAddress) {
      toast.error(t('enterValidWalletAddress'));
      return;
    }
    if (!isAuthenticated) {
      window.location.href = `${API_URL}/api/auth/google`;
      return;
    }

    try {
      const response = await axios.post(`${API_URL}/api/blacklist`, {
        walletAddress,
        riskLevel: 'low', // 預設風險等級
        blackScore: 1,    // 初始分數
        labels: [label],  // 選定的標籤
        comment,          // 備註
        userId,
      });

      // 更新用戶資訊中的"舉報的可疑錢包"
      const userResponse = await axios.post(`${API_URL}/api/add_suspicious_wallet_to_user`, {
        userId,
        walletAddress,
        label,
        comment
      });

      // 如果成功添加到用戶資訊，顯示成功提示
      if (userResponse.status === 200) {
        toast.success(t('walletAddedToBlacklist'));
      }
    } catch (error) {
      // 若已經回報過該地址，顯示相應提示
      if (error.response && error.response.status === 400 && error.response.data.error === '已經回報過該地址') {
        toast.error(error.response.data.error); // 顯示“已經回報過該地址”通知
      } else {
        toast.error(t('errorAddingWalletToBlacklist')); // 其他錯誤情況
      }
    }
  };

  return (
    <div className="bg-gray-100 dark:bg-gray-900 min-h-screen">
      <div
        className="w-full h-64 bg-cover bg-center flex items-center justify-center text-white text-3xl font-bold"
        style={{ backgroundImage: `url('/images/myReportsBanner.png')` }}
      >
        {t('walletRiskAssessment')}
      </div>

      <div className="flex justify-center items-center py-8">
        <form onSubmit={handleSubmit} className="flex items-center space-x-2 w-full max-w-2xl">
          <div className="relative w-full">
            <input
              type="text"
              placeholder={t('inputWalletAddress')}
              value={walletAddress}
              onChange={(e) => setWalletAddress(e.target.value)}
              className="w-full p-3 pl-10 border rounded-md shadow-sm focus:ring focus:ring-blue-200 dark:bg-gray-800 dark:text-white dark:border-gray-700"
            />
            <span className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <FaMagnifyingGlass className="h-5 w-5 text-gray-500 dark:text-gray-400" />
            </span>
          </div>

          <select 
            value={chainId} 
            onChange={(e) => setChainId(parseInt(e.target.value))} 
            className="p-3 border rounded-md shadow-sm dark:bg-gray-800 dark:text-white dark:border-gray-700"
          >
            <option value="-3">Solana</option>
            <option value="-2">TRX</option>
            <option value="-1">Bitcoin</option>
            <option value="1">Ethereum</option>
            <option value="10">Optimism</option>
            <option value="25">Cronos</option>
            <option value="56">BSC</option>
            <option value="100">Gnosis</option>
            <option value="137">Polygon</option>
            <option value="169">Manta Pacific</option>
            <option value="199">Bittorrent</option>
            <option value="250">Fantom</option>
            <option value="288">Boba</option>
            <option value="324">zkSync Era</option>
            <option value="1024">CLV</option>
            <option value="1101">Polygon zkEVM</option>
            <option value="1111">Wemix</option>
            <option value="1284">Moonbeam</option>
            <option value="1285">Moonriver</option>
            <option value="5000">Mantle</option>
            <option value="8453">Base</option>
            <option value="42161">Arbitrum</option>
            <option value="42220">Celo</option>
            <option value="43114">Avalanche</option>
            <option value="59144">Linea</option>
            <option value="81457">Blast</option>
            <option value="1313161554">Aurora</option>

          </select>

          <button type="submit" className="bg-blue-500 text-white px-4 py-3 rounded-md shadow-sm w-32">
            {t('search')}
          </button>
        </form>
      </div>

      {/* 錢包地址標籤 */}
      <div className="container mx-auto py-8">
      {riskData && (
        <div className="bg-white p-6 rounded-md shadow-md dark:bg-gray-800 dark:text-white">
          <h2 className="text-2xl font-bold mb-4">{t('walletRiskDetails')}</h2>
          <p><strong>{t('mainEntity')}:</strong> {mainEntity}</p>
          <p><strong>{t('nameTag')}:</strong> {nameTag}</p>
          {riskData.data && riskData.data.attributes && riskData.data.attributes.length > 0 ? (
            <ul className="list-disc pl-5">
              {riskData.data.attributes.map((attr, index) => (
                <li key={index}>{attr.name}</li>
              ))}
            </ul>
          ) : (
            <p>{t('noWalletTags')}</p>
          )}
        </div>
      )}

      {/* 制裁名單資料 */}
      {sanctionData && (
        <div className="bg-white p-6 mt-6 rounded-md shadow-md dark:bg-gray-800 dark:text-white">
          <h2 className="text-2xl font-bold mb-4">{t('sanctionCheckResult')}</h2>
          {sanctionData.is_sanctioned === true ? (
            <div>
              <p className="text-red-500 font-bold">{t('walletSanctioned')}</p>
              {sanctionData.identifications && sanctionData.identifications.length > 0 && (
                <p>
                  <strong>{t('sanctionSource')}:</strong> {sanctionData.identifications[0].name}
                </p>
              )}
            </div>
          ) : sanctionData.is_sanctioned === false ? (
            <p className="text-green-500 font-bold">{t('walletNotSanctioned')}</p>
          ) : (
            <p className="text-yellow-500 font-bold">{t('unknown')}</p>
          )}
        </div>
      )}




      {/* 合規風險分數 */}
      {complianceRiskData && (
        <div className="bg-white p-6 mt-6 rounded-md shadow-md dark:bg-gray-800 dark:text-white">
          <h2 className="text-2xl font-bold mb-4">{t('complianceRiskScore')}</h2>
          
          {/* 顯示風險分數 */}
          <p><strong>{t('riskScore')}:</strong> {riskScore}</p>
          
          {/* 顯示風險指標 */}
          <div>
            <p><strong>{t('riskIndicators')}:</strong></p>
            {Array.isArray(riskIndicators) && riskIndicators.length > 0 ? (
              <ul className="list-disc pl-5">
                {riskIndicators.map((indicatorObj, index) => (
                  <div key={index} className="mb-2">
                    {typeof indicatorObj.indicator === 'object' ? (
                      <div className="ml-5">
                        <li><strong>{t('indicatorName')}:</strong> {indicatorObj.indicator.name}</li>
                        <li><strong>{t('indicatorCode')}:</strong> {indicatorObj.indicator.code}</li>
                      </div>
                    ) : (
                      <p>{indicatorObj.indicator}</p>
                    )}
                  </div>
                ))}
              </ul>
            ) : (
              <p>{typeof riskIndicators === 'object' ? JSON.stringify(riskIndicators) : t('unknown')}</p>
            )}
          </div>
        </div>
      )}




        {riskData && (
          <div className="mt-6 flex flex-col space-y-4 md:space-y-0 md:flex-row md:space-x-4">
            {/* 備註欄位 */}
            <textarea
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder={t('enterComment')}
              className="w-full md:w-auto p-3 border rounded-md dark:bg-gray-800 dark:text-white dark:border-gray-700 resize-none"
            />

            {/* 標籤下拉選單 */}
            {/* <select
              value={label}
              onChange={(e) => setLabel(e.target.value)}
              className="p-3 border rounded-md shadow-sm dark:bg-gray-800 dark:text-white dark:border-gray-700"
            >
              <option value="scam">{t('labelScam')}</option>
              <option value="phishing">{t('labelPhishing')}</option>
              <option value="moneyLaundering">{t('labelMoneyLaundering')}</option>
              <option value="fraud">{t('labelFraud')}</option>
            </select> */}

            <button
              onClick={handleAddToWhitelist}
              className="bg-green-500 text-white px-4 py-2 rounded-md shadow-sm dark:bg-green-700 dark:text-white"
            >
              {t('thisIsMyWallet')}
            </button>

            <button
              onClick={handleAddToBlacklist}
              className="bg-red-500 text-white px-4 py-2 rounded-md shadow-sm dark:bg-red-700 dark:text-white"
            >
              {t('thisIsSuspiciousWallet')}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default WalletRisk;
