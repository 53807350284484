// frontend/src/pages/Favorites.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import API_URL from '../config';
import MemberSidebar from '../components/MemberSidebar'; // 引入 MemberSidebar 組件
import { toast } from "react-toastify";

function Favorites() {
  const { t } = useTranslation();
  const [favorites, setFavorites] = useState([]);

  useEffect(() => {
    // 取得用戶的收藏列表
    axios
      .get(`${API_URL}/api/favorites`, { withCredentials: true })
      .then((res) => {
        setFavorites(res.data.favorites);
      })
      .catch((err) => {
        console.error(t('errorFetchingFavorites'), err);
      });
  }, [t]);

  return (
    <div className="bg-gray-100 dark:bg-gray-900 min-h-screen">
      {/* 頁面橫幅 */}
      <div
        className="w-full h-64 bg-cover bg-center flex items-center justify-center text-white text-3xl font-bold"
        style={{ backgroundImage: `url('/images/myReportsBanner.png')` }}
      >
        {t('favorites')}
      </div>

      <div className="flex flex-col md:flex-row w-full max-w-7xl mx-auto mt-6 px-4">
        {/* 左側頭像和導航區 */}
        <MemberSidebar activePage="favorites" />

        {/* 右側收藏列表顯示區 */}
        <div className="flex-1 ml-0 md:ml-6">
          <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold mb-4">
              {t('favorites')} ({favorites.length})
            </h2>
            <div className="space-y-4">
              {favorites.map((item) => (
                <div
                  key={item._id}
                  className="flex flex-col md:flex-row items-start md:items-center p-4 border-b border-gray-200 dark:border-gray-700"
                >
                  <div className="flex-1 mb-4 md:mb-0">
                    <p className="text-lg font-semibold">
                      {t('reportId')}: {item._id}
                    </p>
                    <p>
                      {t('incidentType')}: {item.incidentType}
                    </p>
                    {/* 可以在這裡添加更多的報告資訊 */}
                  </div>
                  <div className="flex space-x-2">
                    {/* 查看詳情按鈕 */}
                    <button
                      onClick={() => {
                        // 實現查看詳情的功能
                        // 例如：navigate(`/report/${item._id}`);
                      }}
                      className="bg-blue-500 text-white px-3 py-1 rounded"
                    >
                      {t('viewDetails')}
                    </button>
                    {/* 移除收藏按鈕 */}
                    <button
                      onClick={() => {
                        // 實現移除收藏的功能
                        axios
                          .post(
                            `${API_URL}/api/remove_favorite`,
                            { reportId: item._id },
                            { withCredentials: true }
                          )
                          .then(() => {
                            setFavorites(favorites.filter((fav) => fav._id !== item._id));
                            toast.success(t('favoriteRemoved'));
                          })
                          .catch((err) => {
                            console.error(t('errorRemovingFavorite'), err);
                          });
                      }}
                      className="bg-red-500 text-white px-3 py-1 rounded"
                    >
                      {t('removeFavorite')}
                    </button>
                  </div>
                </div>
              ))}
              {favorites.length === 0 && (
                <p className="text-gray-700 dark:text-gray-300">{t('noFavorites')}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Favorites;
