import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";

function Step2({ nextStep, prevStep, handleChange, values, setFormData }) {
  const { t } = useTranslation();

  // 處理詐騙者地址的變更
  const handleScammerAddressChange = (index, field, value) => {
    const updatedAddresses = [...values.scammerAddresses];
    updatedAddresses[index][field] = value;
    setFormData((prevData) => ({ ...prevData, scammerAddresses: updatedAddresses }));
  };

  const handleAddScammerAddress = () => {
    const updatedAddresses = [
      ...values.scammerAddresses,
      {
        victimWalletAddress: '',
        scammerWalletAddress: '',
        chain: '',
        date: '',
        amount: '',
        currency: '',
        transactionID: '',
        transactionScreenshots: [null],
      },
    ];
    setFormData((prevData) => ({ ...prevData, scammerAddresses: updatedAddresses }));
  };

  const handleRemoveScammerAddress = (index) => {
    const updatedAddresses = values.scammerAddresses.filter((_, i) => i !== index);
    setFormData((prevData) => ({ ...prevData, scammerAddresses: updatedAddresses }));
  };

  const handleTransactionScreenshotChange = (index, file) => {
    const updatedAddresses = [...values.scammerAddresses];
    // 確保 transactionScreenshots 是一個數組
    if (!Array.isArray(updatedAddresses[index].transactionScreenshots)) {
      updatedAddresses[index].transactionScreenshots = [];
    }
    // 更新交易截圖
    updatedAddresses[index].transactionScreenshots[0] = file;
    setFormData((prevData) => ({ ...prevData, scammerAddresses: updatedAddresses }));
  };
  
  const handleRemoveTransactionScreenshot = (index) => {
    const updatedAddresses = [...values.scammerAddresses];
    updatedAddresses[index].transactionScreenshots = [];
    setFormData((prevData) => ({ ...prevData, scammerAddresses: updatedAddresses }));
  };


  // 處理網站變更
  const handleWebsiteChange = (index, value) => {
    const updatedWebsites = [...values.websites];
    updatedWebsites[index].url = value;
    setFormData((prevData) => ({ ...prevData, websites: updatedWebsites }));
  };

  const handleAddWebsite = () => {
    const updatedWebsites = [...values.websites, { url: '' }];
    setFormData((prevData) => ({ ...prevData, websites: updatedWebsites }));
  };

  const handleRemoveWebsite = (index) => {
    const updatedWebsites = values.websites.filter((_, i) => i !== index);
    setFormData((prevData) => ({ ...prevData, websites: updatedWebsites }));
  };

  // 處理代幣ID變更
  const handleTokenIDChange = (index, value) => {
    const updatedTokenIDs = [...values.tokenIDs];
    updatedTokenIDs[index].tokenID = value;
    setFormData((prevData) => ({ ...prevData, tokenIDs: updatedTokenIDs }));
  };

  const handleAddTokenID = () => {
    const updatedTokenIDs = [...values.tokenIDs, { tokenID: '' }];
    setFormData((prevData) => ({ ...prevData, tokenIDs: updatedTokenIDs }));
  };

  const handleRemoveTokenID = (index) => {
    const updatedTokenIDs = values.tokenIDs.filter((_, i) => i !== index);
    setFormData((prevData) => ({ ...prevData, tokenIDs: updatedTokenIDs }));
  };

  // 處理IP地址變更
  const handleIpAddressChange = (index, value) => {
    const updatedIpAddresses = [...values.ipAddresses];
    updatedIpAddresses[index].ip = value;
    setFormData((prevData) => ({ ...prevData, ipAddresses: updatedIpAddresses }));
  };

  const handleAddIpAddress = () => {
    const updatedIpAddresses = [...values.ipAddresses, { ip: '' }];
    setFormData((prevData) => ({ ...prevData, ipAddresses: updatedIpAddresses }));
  };

  const handleRemoveIpAddress = (index) => {
    const updatedIpAddresses = values.ipAddresses.filter((_, i) => i !== index);
    setFormData((prevData) => ({ ...prevData, ipAddresses: updatedIpAddresses }));
  };

  // 處理聯絡方式變更
  const handleContactChange = (index, value) => {
    const updatedContacts = [...values.contacts];
    updatedContacts[index].contact = value;
    setFormData((prevData) => ({ ...prevData, contacts: updatedContacts }));
  };

  const handleAddContact = () => {
    const updatedContacts = [...values.contacts, { contact: '' }];
    setFormData((prevData) => ({ ...prevData, contacts: updatedContacts }));
  };

  const handleRemoveContact = (index) => {
    const updatedContacts = values.contacts.filter((_, i) => i !== index);
    setFormData((prevData) => ({ ...prevData, contacts: updatedContacts }));
  };

  // 表單提交驗證
  const continueStep = (e) => {
    e.preventDefault();
    if (
      !values.scammerAddresses[0].scammerWalletAddress ||
      !values.scammerAddresses[0].victimWalletAddress
    ) {
      toast.error(t('pleaseCompleteFields')); // 驗證必填欄位
    } else {
      nextStep();
    }
  };

  // 渲染詐騙者地址輸入欄位
  const renderScammerAddresses = () => {
    return values.scammerAddresses.map((address, index) => (
      <div
        key={index}
        className="border border-gray-300 dark:border-gray-700 p-4 rounded mb-4 relative bg-white dark:bg-gray-800"
      >
        <div className="flex flex-wrap -mx-2">
          {/* 受害者錢包地址 */}
          <div className="w-full lg:w-1/2 px-2 mb-4">
            <label className="block text-gray-700 dark:text-white mb-2">
              {t('victimWalletAddress')}
              <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              placeholder={t('enterVictimWalletAddress')}
              value={address.victimWalletAddress}
              onChange={(e) =>
                handleScammerAddressChange(index, 'victimWalletAddress', e.target.value)
              }
              className="w-full p-2 border border-gray-300 dark:border-gray-700 rounded dark:bg-gray-900 dark:text-white"
            />
          </div>
          {/* 詐騙者錢包地址 */}
          <div className="w-full lg:w-1/2 px-2 mb-4">
            <label className="block text-gray-700 dark:text-white mb-2">
              {t('scammerWalletAddress')}
              <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              placeholder={t('enterScammerWalletAddress')}
              value={address.scammerWalletAddress}
              onChange={(e) =>
                handleScammerAddressChange(index, 'scammerWalletAddress', e.target.value)
              }
              className="w-full p-2 border border-gray-300 dark:border-gray-700 rounded dark:bg-gray-900 dark:text-white"
            />
          </div>
          {/* 區塊鏈選擇 */}
          <div className="w-full lg:w-1/2 px-2 mb-4">
            <label className="block text-gray-700 dark:text-white mb-2">{t('selectChain')}</label>
            <select
              value={address.chain}
              onChange={(e) => handleScammerAddressChange(index, 'chain', e.target.value)}
              className="w-full p-2 border border-gray-300 dark:border-gray-700 rounded dark:bg-gray-900 dark:text-white"
            >
              <option value="">{t('selectChain')}</option>
              <option value="eth">Ethereum (ETH)</option>
              <option value="btc">Bitcoin (BTC)</option>
              <option value="trx">Tron (TRX)</option>
              <option value="sol">Solana (SOL)</option>
              <option value="matic">Polygon (MATIC)</option>
              <option value="bsc">Binance Smart Chain (BSC)</option>
              <option value="hbar">Hedera (HBAR)</option>
              <option value="ada">Cardano (ADA)</option>
              <option value="ltc">Litecoin (LTC)</option>
              <option value="avax">Avalanche (0x) (AVAX)</option>
              <option value="mvx">MultiversX (MVX)</option>
              <option value="attn">Arbitrum (ATTN)</option>
              <option value="algo">Algorand (ALGO)</option>
              <option value="base">Base (BASE)</option>
            </select>
          </div>
          {/* 受騙的日期 */}
          <div className="w-full lg:w-1/2 px-2 mb-4">
            <label className="block text-gray-700 dark:text-white mb-2">{t('dateOfFraud')}</label>
            <input
              type="date"
              value={address.date}
              onChange={(e) => handleScammerAddressChange(index, 'date', e.target.value)}
              className="w-full p-2 border border-gray-300 dark:border-gray-700 rounded dark:bg-gray-900 dark:text-white"
            />
          </div>
          {/* 金額 */}
          <div className="w-full lg:w-1/2 px-2 mb-4">
            <label className="block text-gray-700 dark:text-white mb-2">{t('amount')}</label>
            <input
              type="number"
              placeholder={t('enterAmount')}
              value={address.amount}
              onChange={(e) => handleScammerAddressChange(index, 'amount', e.target.value)}
              className="w-full p-2 border border-gray-300 dark:border-gray-700 rounded dark:bg-gray-900 dark:text-white"
            />
          </div>
          {/* 幣種 */}
          <div className="w-full lg:w-1/2 px-2 mb-4">
            <label className="block text-gray-700 dark:text-white mb-2">{t('currency')}</label>
            <input
              type="text"
              placeholder={t('enterCurrency')}
              value={address.currency}
              onChange={(e) => handleScammerAddressChange(index, 'currency', e.target.value)}
              className="w-full p-2 border border-gray-300 dark:border-gray-700 rounded dark:bg-gray-900 dark:text-white"
            />
          </div>
          {/* 交易ID */}
          <div className="w-full lg:w-1/2 px-2 mb-4">
            <label className="block text-gray-700 dark:text-white mb-2">
              {t('transactionID')}
            </label>
            <input
              type="text"
              placeholder={t('enterTransactionID')}
              value={address.transactionID}
              onChange={(e) => handleScammerAddressChange(index, 'transactionID', e.target.value)}
              className="w-full p-2 border border-gray-300 dark:border-gray-700 rounded dark:bg-gray-900 dark:text-white"
            />
          </div>

          {/* 上傳交易截圖 */}
          <div className="w-full lg:w-1/2 px-2 mb-4">
            <label className="block text-gray-700 dark:text-white mb-2">
              {t('uploadTransactionScreenshot')}
            </label>
            <input
              type="file"
              onChange={(e) => handleTransactionScreenshotChange(index, e.target.files[0])}
              className="w-full p-2 border border-gray-300 dark:border-gray-700 rounded dark:bg-gray-900 dark:text-white"
            />
            {/* 顯示已選擇的文件名稱 */}
            {address.transactionScreenshots && address.transactionScreenshots[0] && (
              <div className="mt-2 flex items-center bg-gray-100 dark:bg-gray-700 p-2 rounded-lg shadow">
                <p className="text-sm text-gray-800 dark:text-gray-200 mr-4 truncate">
                  <strong>{t('selectedFile')}:</strong> {address.transactionScreenshots[0].name}
                </p>
                <button
                  type="button"
                  onClick={() => handleRemoveTransactionScreenshot(index)}
                  className="ml-auto text-red-500 hover:text-red-700 focus:outline-none bg-red-100 hover:bg-red-200 px-3 py-1 rounded-lg transition"
                >
                  {t('remove')}
                </button>
              </div>
            )}

          </div>
        </div>
        {/* 刪除按鈕 */}
        {values.scammerAddresses.length > 1 && (
          <button
            type="button"
            onClick={() => handleRemoveScammerAddress(index)}
            className="absolute right-4 top-4 text-red-500"
          >
            ✖
          </button>
        )}
      </div>
    ));
  };

  // 渲染網站輸入欄位
  const renderWebsites = () => {
    return values.websites.map((website, index) => (
      <div key={index} className="relative mb-4">
        <input
          type="text"
          placeholder={t('enterWebsiteURL')}
          value={website.url}
          onChange={(e) => handleWebsiteChange(index, e.target.value)}
          className="w-full p-2 border border-gray-300 dark:border-gray-700 rounded dark:bg-gray-900 dark:text-white"
        />
        {values.websites.length > 1 && (
          <button
            type="button"
            onClick={() => handleRemoveWebsite(index)}
            className="absolute right-2 top-2 text-red-500"
          >
            ✖
          </button>
        )}
      </div>
    ));
  };

  // 渲染代幣ID輸入欄位
  const renderTokenIDs = () => {
    return values.tokenIDs.map((token, index) => (
      <div key={index} className="relative mb-4">
        <input
          type="text"
          placeholder={t('enterTokenID')}
          value={token.tokenID}
          onChange={(e) => handleTokenIDChange(index, e.target.value)}
          className="w-full p-2 border border-gray-300 dark:border-gray-700 rounded dark:bg-gray-900 dark:text-white"
        />
        {values.tokenIDs.length > 1 && (
          <button
            type="button"
            onClick={() => handleRemoveTokenID(index)}
            className="absolute right-2 top-2 text-red-500"
          >
            ✖
          </button>
        )}
      </div>
    ));
  };

  // 渲染IP地址輸入欄位
  const renderIpAddresses = () => {
    return values.ipAddresses.map((ip, index) => (
      <div key={index} className="relative mb-4">
        <input
          type="text"
          placeholder={t('enterIpAddress')}
          value={ip.ip}
          onChange={(e) => handleIpAddressChange(index, e.target.value)}
          className="w-full p-2 border border-gray-300 dark:border-gray-700 rounded dark:bg-gray-900 dark:text-white"
        />
        {values.ipAddresses.length > 1 && (
          <button
            type="button"
            onClick={() => handleRemoveIpAddress(index)}
            className="absolute right-2 top-2 text-red-500"
          >
            ✖
          </button>
        )}
      </div>
    ));
  };

  // 渲染聯絡方式輸入欄位
  const renderContacts = () => {
    return values.contacts.map((contact, index) => (
      <div key={index} className="relative mb-4">
        <input
          type="text"
          placeholder={t('enterScammerContact')}
          value={contact.contact}
          onChange={(e) => handleContactChange(index, e.target.value)}
          className="w-full p-2 border border-gray-300 dark:border-gray-700 rounded dark:bg-gray-900 dark:text-white"
        />
        {values.contacts.length > 1 && (
          <button
            type="button"
            onClick={() => handleRemoveContact(index)}
            className="absolute right-2 top-2 text-red-500"
          >
            ✖
          </button>
        )}
      </div>
    ));
  };

  return (
    <div className="p-6 bg-gray-50 dark:bg-gray-900 text-gray-800 dark:text-white">
      <h2 className="text-2xl mb-4 font-bold">{t('scammerInformation')}</h2>
      <p className="text-gray-700 dark:text-gray-300 mb-6">{t('scammerInfoDescription')}</p>

      {/* 詐騙者地址區塊 */}
      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-2">{t('transactionDetails')}</h3>
        <p className="text-gray-600 dark:text-gray-400 mb-4">
          {t('transactionDetailsDescription')}
        </p>
        {renderScammerAddresses()}
        <button
          type="button"
          onClick={handleAddScammerAddress}
          className="mt-2 text-blue-500 hover:text-blue-700 font-semibold py-2 px-4 rounded"
        >
          ＋ {t('addTransactions')}
        </button>
      </div>

      {/* 網站區塊 */}
      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-2">{t('websiteUsedByScammer')}</h3>
        <p className="text-gray-600 dark:text-gray-400 mb-4">{t('websiteDescription')}</p>
        {renderWebsites()}
        <button
          type="button"
          onClick={handleAddWebsite}
          className="mt-2 text-blue-500 hover:text-blue-700 font-semibold py-2 px-4 rounded"
        >
          ＋ {t('addWebsite')}
        </button>
      </div>

      {/* 代幣ID區塊 */}
      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-2">{t('stolenTokenID')}</h3>
        <p className="text-gray-600 dark:text-gray-400 mb-4">{t('tokenIDDescription')}</p>
        {renderTokenIDs()}
        <button
          type="button"
          onClick={handleAddTokenID}
          className="mt-2 text-blue-500 hover:text-blue-700 font-semibold py-2 px-4 rounded"
        >
          ＋ {t('addTokenID')}
        </button>
      </div>

      {/* IP地址區塊 */}
      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-2">{t('scammerIpAddress')}</h3>
        <p className="text-gray-600 dark:text-gray-400 mb-4">{t('ipAddressDescription')}</p>
        {renderIpAddresses()}
        <button
          type="button"
          onClick={handleAddIpAddress}
          className="mt-2 text-blue-500 hover:text-blue-700 font-semibold py-2 px-4 rounded"
        >
          ＋ {t('addIpAddress')}
        </button>
      </div>

      {/* 聯絡方式區塊 */}
      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-2">{t('scammerContact')}</h3>
        <p className="text-gray-600 dark:text-gray-400 mb-4">{t('contactDescription')}</p>
        {renderContacts()}
        <button
          type="button"
          onClick={handleAddContact}
          className="mt-2 text-blue-500 hover:text-blue-700 font-semibold py-2 px-4 rounded"
        >
          ＋ {t('addContact')}
        </button>
      </div>

      {/* 導航按鈕 */}
      <div className="flex justify-between">
        <button
          type="button"
          onClick={prevStep}
          className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded"
        >
          {t('previous')}
        </button>
        <button
          type="button"
          onClick={continueStep}
          className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
        >
          {t('next')}
        </button>
      </div>
    </div>
  );
}

export default Step2;
