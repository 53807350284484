import React, { useState } from 'react';
import '../../App.css';
import { useTranslation } from 'react-i18next';

function ScamQuiz({ onClose, setIncidentType }) {
  const { t } = useTranslation();

  // 定義問卷的步驟和各自的選項
  const quizSteps = {
    '1': {
      question: t('howDidItOccur'),
      options: [
        { label: t('blackmailedSendCrypto'), value: 'blackmail' },
        { label: t('trickedTransferCrypto'), value: 'fraud' },
        { label: t('unauthorizedPayment'), value: 'hack' },
      ],
    },
    'blackmail': {
      question: t('specifyBlackmailType'),
      options: [
        { label: t('blackmailSextortion_description'), value: 'blackmailSextortion' },
        { label: t('blackmailRansomware_description'), value: 'blackmailRansomware' },
        { label: t('blackmailOther'), value: 'blackmailOther' },
      ],
    },
    'fraud': {
      question: t('specifyFraudType'),
      options: [
        { label: t('fraudNftAirdrop_description'), value: 'fraudNftAirdrop' },
        { label: t('fraudPhishing_description'), value: 'fraudPhishing' },
        { label: t('fraudImpersonation_description'), value: 'fraudImpersonation' },
        { label: t('fraudDonation_description'), value: 'fraudDonation' },
        { label: t('fraudRomance_description'), value: 'fraudRomance' },
        { label: t('fraudPigButchering_description'), value: 'fraudPigButchering' },
        { label: t('fraudFakeProject_description'), value: 'fraudFakeProject' },
        { label: t('fraudRugPull_description'), value: 'fraudRugPull' },
        { label: t('fraudFakeReturns_description'), value: 'fraudFakeReturns' },
        { label: t('fraudOther'), value: 'fraudOther' },
      ],
    },
    'hack': {
      question: t('specifyHackType'),
      options: [
        { label: t('hackSimSwap_description'), value: 'hackSimSwap' },
        { label: t('hackContractExploit_description'), value: 'hackContractExploit' },
        { label: t('hackOther'), value: 'hackOther' },
      ],
    },
  };

  // 狀態，用於追蹤問卷歷史和使用者的選擇
  const [history, setHistory] = useState(['1']);
  const [answers, setAnswers] = useState({});
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const currentStepKey = history[history.length - 1];
  const currentStepData = quizSteps[currentStepKey];

  const handleOptionSelect = (value) => {
    setAnswers({ ...answers, [history.length]: value });
  
    if (quizSteps[value]) {
      // 如果有下一步，繼續問卷
      setHistory([...history, value]);
    } else {
      // 最終選擇，更新事件類型，並顯示結果
      setIncidentType(value);
  
      // 找到選擇的選項，用於顯示結果
      const optionLabel = currentStepData.options.find(option => option.value === value).label;
      setSelectedOption({ value, label: optionLabel });
  
      setQuizCompleted(true); // 問卷完成
    }
  };

  const handlePrevious = () => {
    if (history.length > 1) {
      // 返回上一個步驟
      setHistory(history.slice(0, -1));
    } else {
      // 如果在第一步，關閉問卷
      onClose();
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 dark:bg-opacity-80">
      <div className="bg-white dark:bg-gray-800 w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
        <div className="py-6 px-4 md:px-8 text-gray-900 dark:text-white">
          {quizCompleted ? (
            <>
              <h3 className="text-lg md:text-xl font-bold mb-6">
                {t('yourIncidentTypeIs')}：{t(selectedOption.value)}
              </h3>
              <button
                type="button"
                onClick={onClose}
                className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded w-full"
              >
                {t('confirm')}
              </button>
            </>
          ) : (
            <>
              <h3 className="text-lg md:text-xl font-bold mb-6">{currentStepData.question}</h3>
              {currentStepData.options.map((option) => (
                <button
                  key={option.value}
                  type="button"
                  onClick={() => handleOptionSelect(option.value)}
                  className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded mb-4 w-full text-left"
                >
                  {option.label}
                </button>
              ))}
              <div className="flex flex-col md:flex-row justify-between mt-8">
                <button
                  type="button"
                  onClick={handlePrevious}
                  className="bg-gray-500 hover:bg-gray-600 text-white font-semibold py-2 px-4 rounded mb-2 md:mb-0 md:mr-2 w-full md:w-auto"
                >
                  {t('previous')}
                </button>
                <button
                  type="button"
                  onClick={onClose}
                  className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded w-full md:w-auto"
                >
                  {t('close')}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ScamQuiz;
