import React from 'react';
import API_URL from '../config'; 

function Login() {
  const googleLogin = () => {
    window.location.href = `${API_URL}/api/auth/google`; // 這是後端的 Google OAuth 路徑
  };

  return (
    <div className="login-container">
      <h2>Login with Google</h2>
      <button onClick={googleLogin} className="google-btn">
        Login with Google
      </button>
    </div>
  );
}

export default Login;
